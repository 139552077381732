





















import Vue from 'vue'
import Zondicon from 'vue-zondicons'

export default Vue.extend({
  components: { Zondicon },
  props: {
    username: {
      type: String,
      default: null,
    },
  },
})
