













































































import Vue from 'vue'

import { authAPI } from '@/utils/authentication'

export default Vue.extend({
  props: {
    resetId: { type: String, default: null },
    username: { type: String, default: null },
  },
  data: () => ({
    password: '',
    passwordConfirmation: '',
    errorMessage: null as null | string,
    loading: false,
  }),
  methods: {
    async formSubmit() {
      if (this.loading) return
      this.loading = true
      this.errorMessage = null

      const { username, password, passwordConfirmation } = this
      if (!username) {
        this.errorMessage = 'The username must be provided.'
      } else if (!password || password.length < 6) {
        this.errorMessage =
          'Un nouveau mot de passe d’au moins 6 caractères doit être renseigné.'
      } else if (
        !passwordConfirmation ||
        password.localeCompare(passwordConfirmation) !== 0
      ) {
        this.errorMessage =
          'Le mot de passe et sa confirmation doivent être égaux.'
      }

      if (this.errorMessage) {
        this.loading = false
        return
      }

      try {
        await authAPI.resetPasswordAuthResetPut({
          reset_id: this.resetId,
          username,
          new_password: password,
        })
      } catch (error) {
        this.errorMessage =
          'Le mot de passe n’a pas pu être réinitialisé. ' +
          'Le lien de l’email a peut-être expiré, ou votre connexion internet est peut-être instable. ' +
          'Merci de réessayer ultérieurement...'
        this.loading = false
        return
      }

      this.$router.push({ name: 'ResetDone', query: { username } })
      this.loading = false
    },
  },
})
